import SVGInjector from 'svg-injector'
import SmoothScroll from 'smooth-scroll'
import Glide from '@glidejs/glide'
import GLightbox from 'glightbox'
import simpleParallax from 'simple-parallax-js';

//svg injection
const mySVGsToInject = document.querySelectorAll('svg[data-src]');
SVGInjector(mySVGsToInject)

window.onload = new function(){
	var backobjs = document.getElementsByClassName('backobj__item');
	for(let i = 0; i < backobjs.length; i++){
		new simpleParallax(backobjs[i], {
			overflow: true,
			scale: 1.5
		})
	}

	new SmoothScroll('a[href*="#"]', {
		speed: 1000
	})

	// hamburger menu
	const header = document.getElementById('header')
	const hamburger = document.getElementById('hamburger')
	if(hamburger && header){
		hamburger.addEventListener('click', function(){
			header.classList.toggle('header--mobile-active')
		})
	}

	//herobannerslider
	const homeslider = document.getElementsByClassName('home-overlay-slider__slider')
	if(homeslider.length > 0){
		console.log(homeslider)
		var slides = document.querySelectorAll('.home-overlay-slider__slider .glide__slide')
		if(slides.length > 1){
			new Glide(homeslider[0], {
				type: 'carousel',
				animationDuration: 800,
				animationTimingFunc: 'ease-in-out',
				autoplay: 8000,
			}).mount()
		}else{
			document.querySelector('.hero-banner__slider .glide__arrows').style.display = 'none';
		}
	}

	//lightbox
	const lightbox = GLightbox({
		selector: '.lightbox',
		touchNavigation: true,
		type: 'image',
		loop: true,
	});

	var lightboxtriggers = document.getElementsByClassName('lightbox-trigger')
	for(let i = 0; i < lightboxtriggers.length; i++){
		lightboxtriggers[i].addEventListener('click', function(e){
			lightbox.open()
		})
	}
}

